.head {
  position: relative;
  background: linear-gradient(98.59deg, #4E55FF 8.54%, #3A7CFD 34.57%, #24A1FB 62.94%, #00DAF8 109.42%);
  text-align: center;
  z-index: 20;
  min-height: 555px;

  &__small {
    min-height: 260px;
    max-height: 260px;
  }

  &__title {
    padding-top: 98px;
    max-width: 740px;
    color: #fff;
    margin: 0 auto;
  }

  &__info {
    position: absolute;
    left: 50%;
    bottom: -80px;
    transform: translateX(-50%);
    max-width: 1075px;
    width: 100%;
    min-height: 160px;
    background-color: #fff;
    box-shadow: 0px 45px 92px rgba(104, 157, 219, 0.09);
    border-radius: 18px;
    padding: 43px 80px;
    .new-first-info {
      background: linear-gradient(98.59deg, #4e55ff 8.54%, #3a7cfd 34.57%, #24a1fb 62.94%, #00daf8 109.42%);
      color: white;
      padding: 30px;
      border-radius: 20px;
      width: 35%;
    }
  }

  &__search {
    position: absolute;
    left: 50%;
    bottom: -35px;
    transform: translateX(-50%);
    max-width: 1075px;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 45px 92px rgba(104, 157, 219, 0.09);
    border-radius: 18px;
    padding: 17px 18px;
  }

  &__overlay {
    position: absolute;
    z-index: 5;
    background: linear-gradient(180deg, rgba(248, 249, 253, 0.43) 0%, #F8F9FD 87.98%);
    backdrop-filter: blur(29px);
    width: 100%;
    // height: 1545px;
    height: 100%;
    left: 0;
    right: 0;
    // margin-top: -60px;

    &--profile {
      height: 978px;
    }
  }

  &__soon {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    left: 50%;
    bottom: -30px;
    transform: translateX(-50%);
    max-width: 855px;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 45px 92px rgba(104, 157, 219, 0.09);
    border-radius: 18px;
    padding: 35px 0;
    z-index: 10;
  }

  &__connect {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    left: 50%;
    bottom: -30px;
    transform: translateX(-50%);
    max-width: 855px;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 45px 92px rgba(104, 157, 219, 0.09);
    border-radius: 18px;
    padding: 35px 0 40px;
    z-index: 10;

    .head__soon-text {
      margin: 18px 0;
    }

    .wallet {
      margin: 0 auto;
    }
  }

  &__soon-wrapper {
    position: relative;
    z-index: 10;
  }

  &__soon-title {
    font-family: "Rubik", sans-serif;
    font-size: 32px;
    line-height: 41px;
    text-align: center;
    text-transform: uppercase;
  }

  &__soon-text {
    margin-top: 35px;
    max-width: 340px;
  }

  &__soon-roadmap {
    margin-top: 18px;
    background: linear-gradient(92.05deg, #FFAB5D 7.98%, #FEE97B 131.44%);
    border-radius: 8px;
    padding: 12px 17px;
    font-family: "Rubik", sans-serif;
    font-size: 16px;
    line-height: 19px;
    font-weight: 700;
    color: #fff;

    &:hover {
      opacity: 0.6;
    }
  }

  &__list {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__item-count {
    position: relative;

    &--uncommon {
      color: #49CF6F;
    }

    &--rare {
      color: #46D1E5;
    }

    &--epic {
      color: #E562DF;
    }

    &--legendary {
      color: #F3AC40;
    }

    span {
      position: absolute;
      top: -10px;
      right: -20px;
      display: block;
      font-size: 12px;
      line-height: 16px;
      font-weight: 700;
      letter-spacing: 0.18em;
      text-shadow: 2px 1px 3px rgba(212, 160, 123, 0.34);
      color: transparent;
      -webkit-background-clip: text;
      background-image: linear-gradient(92.05deg, #FFAB5D 7.98%, #FEE97B 131.44%);
      text-transform: uppercase;
      transform: rotate(16.39deg);
    }
  }

  &__item-status {
    margin-top: 10px;
    font-size: 12px;
    line-height: 12px;
    font-weight: 600;

    &:first-child {
      font-family: "Rubik", sans-serif;
      font-size: 16px;
      line-height: 21px;
      color: #B3B4CA;
    }
  }
}

.topxtx__premint {
  padding-top: 175px;
}

@media (max-width: 768px) {
  .head__info .new-first-info {
    width: 100%;
  }
  .timer::before {
    left: -10px;
  }
}