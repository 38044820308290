.referral {
  margin-top: 130px;
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #E5E8F3;
  border-radius: 12px;

  &__top {
    min-height: 62px;
    border-bottom: 1px solid #E5E8F3;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 32px;
  }

  &__title {
    font-family: "Rubik", sans-serif;
    font-size: 16px;
    line-height: 21px;
    font-weight: 500;
  }

  &__status {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      background-image: url('../../assets/referral.png');
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      width: 16px;
      height: 27px;
      top: -2px;
      left: -20px;
    }

    span {
      font-size: 16px;
      line-height: 27px;
      color: #B3B4CA;
    }

    &--done {
      span {
        font-weight: 700;
        color: #2B2D65;
      }
    }
  }

  &__btn {
    display: block;
    padding: 24px 43px 24px 32px;
    font-size: 14px;
    line-height: 24px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='20' height='18' viewBox='0 0 20 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M13.9667 3.85714V1.68571C13.9667 1.50385 13.895 1.32944 13.7675 1.20084C13.64 1.07224 13.467 1 13.2867 1H2.18C1.99965 1 1.82669 1.07224 1.69917 1.20084C1.57164 1.32944 1.5 1.50385 1.5 1.68571V12.8857C1.5 13.0676 1.57164 13.242 1.69917 13.3706C1.82669 13.4992 1.99965 13.5714 2.18 13.5714H6.03334' stroke='%232B2D65' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3e%3cpath d='M17.8199 16.9999H6.7132C6.53286 16.9999 6.3599 16.9277 6.23237 16.7991C6.10485 16.6705 6.0332 16.496 6.0332 16.3142V5.11418C6.0332 4.93232 6.10485 4.7579 6.23237 4.62931C6.3599 4.50071 6.53286 4.42847 6.7132 4.42847H17.8199C18.0002 4.42847 18.1732 4.50071 18.3007 4.62931C18.4282 4.7579 18.4999 4.93232 18.4999 5.11418V16.3142C18.4999 16.496 18.4282 16.6705 18.3007 16.7991C18.1732 16.9277 18.0002 16.9999 17.8199 16.9999Z' stroke='%232B2D65' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e ");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      width: 24px;
      height: 24px;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.referral-bid {
  &__container {
    max-width: 1105px;
    padding: 0 15px;
    margin: 0 auto;
    position: relative;
    background-color: #FFFFFF;
    box-shadow: 0px 45px 92px 0px #689DDB17;
    border-radius: 18px;
    padding: 48px;
    display: flex;
    justify-content: space-between;
  }
  &__title {
    font-family: Rubik;
    font-size: 32px;
    font-weight: 500;
    line-height: 41.6px;
    text-align: left;
    color: #2B2D65;
    margin-bottom: 24px;
    text-transform: uppercase;
    span {
      background: linear-gradient(98.59deg, #4E55FF 8.54%, #3A7CFD 34.57%, #24A1FB 62.94%, #00DAF8 109.42%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  &__text {
    font-family: Rubik;
    font-size: 16px;
    font-weight: 400;
    line-height: 27.2px;
    text-align: left;
    color: #2B2D65;
  }

  &__left-block {
    width: 52%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__right-block {
    width: 45%;
  }
  &__link {
    background: linear-gradient(98.59deg, #4E55FF 8.54%, #3A7CFD 34.57%, #24A1FB 62.94%, #00DAF8 109.42%);
    padding: 24px;
    border-radius: 12px;
    margin-bottom: 24px;
    p {
      color: #FFFFFF;
      font-family: Rubik;
      font-weight: 500;
      font-size: 18px;
      margin-bottom: 12px;
      &.notActive {
        font-weight: 400;
        font-size: 14px;
        line-height: 18.2px;
        color: #B3B4CA;
        margin-bottom: 0;
      }
      &.active {
        font-weight: 400;
        font-size: 14px;
        color: #2B2D65;
        margin-bottom: 0;
        width: 85%; /* Ограничение ширины контейнера */
        white-space: nowrap; /* Отключаем перенос строки */
        overflow: hidden; /* Скрываем текст, который не помещается */
        text-overflow: ellipsis; /* Добавляем троеточие в конце */
      }
    }
  }
  &__input {
    display: flex;
    justify-content: space-between;
    background-color: #FFFFFF;
    padding: 8px;
    border-radius: 8px;
  }
  &__dashboard {
    border: 1px solid #E5E8F3;
    border-radius: 12px;
    padding: 24px;
    p {
      color: #2B2D65;
      font-family: Rubik;
      font-weight: 500;
      font-size: 18px;
      margin-bottom: 24px;
      &.left {
        font-weight: 400;
        font-size: 14px;
        color: #B3B4CA;
      }
      &.right {
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
  &__item {
    display: flex;
    justify-content: space-between;
  }
  &__line {
    flex-grow: 1;
    border-bottom: 1px dashed #E5E8F3; /* Пунктирная линия */
    margin: 0 10px;
  }
}

@media (max-width: 768px) {
  .referral-bid__container {
    flex-direction: column;
    padding: 24px;
  }
  .referral-bid__left-block {
    width: 100%;
    margin-bottom: 16px;
  }
  .referral-bid__right-block {
    width: 100%;
  }
  .referral-bid__title {
    text-align: center;
  }
  .dash-54 {
    button {
      display: flex;
      align-items: center;
    }
  }
  .refs.form-control {
    width: 90px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}