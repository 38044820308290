.header {
  border-bottom: 1px solid #E5E8F3;
  height: 76px;

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__logo {
    display: block;
    height: 86px;
    width: 76px;

    img {
      object-fit: contain;
    }
  }

  &__nav {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  &__nav-list {
    display: flex;
    align-items: center;

    li + li {
      margin-left: 60px;
    }
  }

  &__btns {
    position: relative;
    width: 255px;
    display: flex;
    justify-content: space-between;
  }
}

#wallet .wallet__tabs-list .wallet__tabs-item:last-child {
  display: none;
}

#wallet .wallet__tabs-list .wallet__tabs-item:first-child {
  width: 100%;
}

#wallet .wallet__tabs-item>.wallet__tabs-btn.wallet__tabs-btn--active {
  width: 100%;
  display: block;
}

#copyurl999 {
  font-size: 10px;
}