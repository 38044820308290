
/* stylelint-disable */

@mixin for-desktop {
  @media (min-width: (1440px)) {
    @content;
  }
}

@mixin for-laptop  {
  @media (min-width: (1240px)) {
    @content;
  }
}

@mixin for-tablet {
  @media (min-width: (1024px)) {
    @content;
  }
}

@mixin for-small-tablet {
  @media (min-width: (768px)) {
    @content;
  }
}


@mixin for-mobile {
  @media (min-width: (576px)) {
    @content;
  }
}

@mixin for-ios {
  @media (min-width: (414px)) {
    @content;
  }
}

@mixin desktop {
  @media (max-width: (1440px)) {
    @content;
  }
}

@mixin laptop {
  @media (max-width: (1239px)) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: (1024px)) {
    @content;
  }
}

@mixin small-tablet {
  @media (max-width: (767px)) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: (576px)) {
    @content;
  }
}

@mixin ios {
  @media (max-width: (414px)) {
    @content;
  }
}

/* stylelint-disable */

@mixin burger {
  --burger-width: 23px;
  --burger-height: 15px;
  --burger-line-height: 2px;
  --border-radius: 30px;

  position: relative;
  border: none;
  padding: 0;
  width: var(--burger-width);
  height: var(--burger-height);
  color: rgba(var(--cl-c),1.0);
  background-color: transparent;
  flex-shrink: 0;
  transition: all .3s;
  cursor: pointer;

  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: var(--burger-line-height);
    background-color: currentColor;
    transition: transform 0.3s ease-in-out, top 0.3s ease-in-out;
    border-radius: var(--border-radius);
  }

  &::before {
    top: 0;
  }

  &::after {
    top: calc(100% - var(--burger-line-height));
  }

  &__line {
    position: absolute;
    right: 0;
    top: 50%;
    width: 100%;
    height: var(--burger-line-height);
    background-color: currentColor;
    transform: translateY(-50%);
    transition: transform 0.3s ease-in-out;
    border-radius: var(--border-radius);
  }

  &.active {
    &::before {
      top: 45%;
      transform: rotate(45deg);
      transition: transform 0.3s ease-in-out, top 0.3s ease-in-out;
    }

    &::after {
      top: 45%;
      transform: rotate(-45deg);
      transition: transform 0.3s ease-in-out, top 0.3s ease-in-out;
    }

    .burger__line {
      transform: scale(0);
      transition: transform 0.3s ease-in-out;
    }
  }
}

.burger {
  @include burger;
  display: flex;
  position: relative;
  z-index: 91;
}

/* stylelint-disable */
@mixin gradient-border($background, $border-width) {
  position: relative;
  &::before {
    pointer-events: none;
    content: "";
    position: absolute;
    inset: 0;
    border-radius: inherit;
    padding: #{$border-width};
    background: #{$background};
    -webkit-mask:
            linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
}


@mixin dfc {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin dfcs {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin psevdo {
  position: absolute;
  content: '';
}

@mixin bg_position {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
}

@mixin bg_cover {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}


$browser-context: 16; // Default

@function rem($pixels, $context: $browser-context) {
  @return #{$pixels/$context}rem;
}


@mixin image-set($pathToImage) {
  background-image: url("#{$pathToImage}.png");
  /* stylelint-disable-next-line declaration-block-no-duplicate-properties */
  background-image: image-set(
    /* stylelint-disable-next-line function-comma-newline-after */
    /*"#{$pathToImage}.avif"type("image/avif"),*/
                  "#{$pathToImage}.webp"type("image/webp"),
                  "#{$pathToImage}.png"type("image/png")
  );
}

@mixin footerToBottom {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
}

@mixin mr($value) {
  &:not(:last-child) {
    margin-right: $value;
  }
}

@mixin ml($value) {
  &:not(:last-child) {
    margin-left: $value;
  }
}

@mixin mb($value) {
  &:not(:last-child) {
    margin-bottom: $value;
  }
}

@mixin mt($value) {
  &:not(:last-child) {
    margin-top: $value;
  }
}

@mixin overlay {
  position: fixed;
  height: 100%;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 20;
  padding: 20px 20px;
  overflow-y: scroll;
  background:  var(--overlay-bg);
  visibility: hidden;
  opacity: 0;

  transition: all 1s;
  transition-delay: 0.5s;

  pointer-events: none;



  &::-webkit-scrollbar {
    display: none;
  }

  &.active {
    display: grid;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(16px);


    visibility: visible;
    opacity: 1;

    transition: all 0.6s;

    pointer-events: initial;
  }
}

.overlay {
  @include overlay;
}
