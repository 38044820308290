html,
body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: "NotoSans", sans-serif;
  font-size: 16px;
  line-height: 27px;
  font-weight: 400;
  color: #2B2D65;
  background-color: #F8F9FD;
}

.body__scroll {
  overflow-y: hidden;
}

html {
  box-sizing: border-box;
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

img {
  width: 100%;
  height: 100%;
  border-style: none;
  object-fit: cover;
  font-size: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

ul,
li,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

input,
textarea,
select {
  background-color: transparent;
  border: none;
  outline: none;
}

button {
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  color: inherit;
  padding: 0;
}

a {
  display: inline-block;
  color: inherit;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.container {
  max-width: 1300px;
  padding: 0 15px;
  margin: 0 auto;

  &__small {
    max-width: 1160px;
    padding: 0 15px;
    margin: 0 auto;
  }

  &__content {
    max-width: 1105px;
    padding: 0 15px;
    margin: 0 auto;
    position: relative;
  }

  &__overlay {
    max-width: 1105px;
    width: 100%;
    padding: 0 15px;
    margin: 0 auto;
    position: relative;
    display: flex;
    justify-content: space-between;
  }
}

@media (min-width: 1000) and (max-width: 1600px) {
  .container {
    max-width: 1330px;
    padding: 0 15px;
    margin: 0 auto;
  }
}

.profile {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 81px;
  height: 42px;
  border: 1px solid #4E55FF;
  border-radius: 8px;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
  color: #4E55FF;
  transition: font-weight 0.1s ease-in;
  cursor: pointer;

  &:hover {
    font-weight: 700;
    font-size: 14px;
  }
}

.disconnect {
  margin-top: 35px;
  width: 100%;
  height: 42px;
  border: 1px solid #FFAB5D;
  border-radius: 8px;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
  color: transparent;
  -webkit-background-clip: text;
  background-image: linear-gradient(92.05deg, #FFAB5D 7.98%, #FEE97B 131.44%);
  transition: font-weight 0.1s ease;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  padding: 11px 0;

  &:hover {
    font-weight: 500;
  }
}

.arrow__down {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);

  &-image {
    animation: arrow-down 2s infinite;

    @keyframes arrow-down {
      0% {
        opacity: 0;
        transform: translateY(-10px);
      }

      50% {
        opacity: 1;
      }

      100% {
        opacity: 0;
        transform: translateY(5px);
      }
    }
  }
}

.title {
  padding-top: 40px;
  font-family: "Rubik", sans-serif;
  font-size: 62px;
  line-height: 75px;
  font-weight: 500;
  color: #fff;

  span {
    display: block;
    color: transparent;
    -webkit-background-clip: text;
    background-image: linear-gradient(92.05deg, #FFAB5D 7.98%, #FEE97B 131.44%);
  }
}

.subtitle {
  font-family: "Rubik", sans-serif;
  font-size: 62px;
  line-height: 75px;
  font-weight: 500;

  span {
    color: transparent;
    -webkit-background-clip: text;
    background-image: linear-gradient(98.59deg, #4E55FF 8.54%, #3A7CFD 34.57%, #24A1FB 62.94%, #00DAF8 109.42%);
  }
}

.descr {
  font-family: "Rubik", sans-serif;
  font-size: 32px;
  line-height: 42px;
  font-weight: 500;
  text-transform: uppercase;
}

.text {
  margin-top: 28px;

  &__rare {
    color: #46D1E5;
    font-weight: 700;
  }

  &__epic {
    color: #E562DF;
    font-weight: 700;
  }

  &__legendary {
    color: #F3AC40;
    font-weight: 700;
  }

  &__common {
    color: #49CF6F;
    font-weight: 700;
  }

  &__uncommon {
    color: #96A2B0;
    font-weight: 700;
  }
}

.checkout {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 42px;
  background: linear-gradient(98.59deg, #4E55FF 8.54%, #3A7CFD 34.57%, #24A1FB 62.94%, #00DAF8 109.42%);
  border-radius: 8px;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  line-height: 19px;
  font-weight: 700;
  color: #fff;
  transition: opacity 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }
}

.uncommon {
  background-color: #49CF6F;
}

.epic {
  background-color: #E562DF;
}

.legendary {
  background-color: #F3AC40;
}

.common {
  background-color: #96A2B0;
}

.rare {
  background-color: #46D1E5;
}


.arrow__mint {
  margin-top: 30px;

  img {
    width: 44px;
    height: 24px;
  }
}


.scroll__top {
  position: fixed;
  right: 50px;
  width: 36px;
  height: 36px;
  background: linear-gradient(92.05deg, #FFAB5D 7.98%, #FEE97B 131.44%);
  border-radius: 50%;
  cursor: pointer;
  opacity: 0;
  transition: 0.2s;

  &:hover {
    opacity: 0.7;
  }

  &--show {
    opacity: 1;
    top: 30%;
    transform: translateY(-70%);
  }
}

main {
  position: relative;
}

.body-scroll {
  overflow-y: hidden;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
  background-color: transparent;
  transition: 0.2s;
}
