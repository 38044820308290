.farm {
  margin: 78px auto 0;
  max-width: 1300px;
  min-height: 473px;
  background: linear-gradient(98.59deg, #4E55FF 8.54%, #3A7CFD 34.57%, #24A1FB 62.94%, #00DAF8 109.42%);
  border-radius: 24px;

  &__image {
    position: absolute;
    right: 0;
    width: 237px;
    height: 249px;
    padding: 12px;
    border-radius: 22px;
    background-color: #fff;
    box-shadow: 0px 27px 61px rgba(188, 197, 235, 0.58);

    img {
      width: 100%;
      height: 100%;
      border-radius: 17px;
    }

    &--top {
      top: -10px;
      right: 85px;
      transform: rotate(-17.97deg);
      z-index: 15;
    }

    &--bot {
      bottom: -10px;
      transform: rotate(17.97deg);
      z-index: 15;
    }
  }


  &__inner {
    position: relative;
    padding: 75px 0;
  }

  &__title {
    max-width: 530px;
    font-family: "Rubik";
    font-size: 32px;
    line-height: 42px;
    font-weight: 700;
    color: #fff;
  }

  &__info {
    margin-top: 38px;
    max-width: 702px;
    display: flex;
    justify-content: space-between;
  }

  &__info-list {
    max-width: 185px;

    li + li {
      margin-top: 28px;
    }
  }

  &__info-item {
    display: flex;
    align-items: center;
    height: 26px;

    &:first-child {
      font-size: 12px;
      line-height: 15px;
      font-weight: 500;
      color: #BEC8FF;
      min-height: 35px;
    }

    span {
      font-family: "Rubik";
      font-size: 16px;
      line-height: 18px;
      font-weight: 700;
      color: #fff;
    }

    .card__status span {
      font-size: 14px;
      line-height: 14px;
      font-weight: 500;
    }
  }

  &__card {
    padding: 34px;
    background-color: #FFFFFF;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__card-image {
    max-width: 244px;
    max-height: 244px;
    border-radius: 10px;

    img {
      border-radius: 10px;
    }
  }

  &__card-info {
    display: flex;
    flex-direction: column;

    .farm__card-title + .farm__card-title {
      margin-top: 15px;
    }
  }

  &__card-title {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 17px;
    color: #B3B4CA;

    span {
      margin-left: 5px;
      font-weight: 500;
      color: #2B2D65;
    }

    .farm__card-rarity {
      margin-left: 5px;

      svg {
        margin: 0;
      }

      span {
        margin-left: 3px;
        color: #fff;
        font-weight: 600;
      }
    }

    .farm__card-farming {
      color: #FF9F47;
    }
  }

  &__card-result {
    margin-top: 40px;
    font-size: 14px;
    line-height: 19px;
    font-weight: 500;
    color: #2B2D65;

    span {
      font-size: 18px;
      line-height: 24px;
      font-weight: 700;
      color: #4E55FF;
    }
  }

  &__overlay-box {
    margin-top: 85px;
    position: relative;
  }

  &__connect {
    position: absolute;
    max-width: 856px;
    width: 100%;
    min-height: 223px;
    left: 50%;
    transform: translateX(-50%);
    top: 130px;
    background: #FFFFFF;
    box-shadow: 0px 45px 92px rgba(104, 157, 219, 0.09);
    border-radius: 18px;
    z-index: 10;
  }

  &__connect-wrapp {
    display: flex;
    flex-direction: column;
    align-items: center;

    .head__soon-title {
      margin-top: 35px;
    }

    .head__soon-text {
      margin: 18px 0;
    }
  }

  &__overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    background: linear-gradient(180deg, rgba(248, 249, 253, 0.43) 0%, #F8F9FD 87.98%);
    backdrop-filter: blur(29px);
  }

}




