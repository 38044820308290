.roadmap {
  position: relative;
  overflow: hidden;

  &__container {
    width: 190%;
  }
  &__infinite {
    display: flex;
    align-items: center;
    //white-space: nowrap;
    width: 100%;
    //height: 56px;
    overflow: hidden;
  }
  &__slide {
    //margin: 0px 275px;
    animation: loopText 1s infinite linear;
  }
  @keyframes loopText {

    from {
      transform: translateX(0);
    }

    to {
      transform: translateX(-100%);
    }

  }
  &::before {
    content: "";
    position: absolute;
    background-image: url('../../assets/roadmap.svg');
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    left: 160px;
    top: 0px;
    z-index: 1;
  }

  &__way {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & + & {
      margin-top: 110px;
    }

    &:nth-child(even) {
      justify-content: flex-end;
    }
  }

  &__way-items {
    max-width: 525px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__way-item {
    position: relative;
    width: 192px;
    height: 128px;
    border: 1px solid #B3B4CA;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #F8F9FD;
    z-index: 10;

    &--now {
      border: 1px solid #4e55ff ;
    }
  }

  &__way-image {
    position: absolute;
    top: -127px;
    left: 50%;
    transform: translateX(-50%);
    width: 52px;
    height: 52px;
    border-radius: 50%;
  }

  &__way-img {
    border-radius: 50%;
  }

  &__way-title {
    margin-top: 10px;
    font-family: "Rubik", sans-serif;
    font-size: 16px;
    line-height: 21px;
    font-weight: 400;

    &--now {
      color: transparent;
      -webkit-background-clip: text;
      background-image: linear-gradient(98.59deg, #4E55FF 8.54%, #3A7CFD 34.57%, #24A1FB 62.94%, #00DAF8 109.42%);
    }
  }

  &__way-data {
    margin-top: 15px;
    font-size: 12px;
    line-height: 15px;
    font-weight: 500;
    color: #B3B4CA;
  }
}

.road-swiper{
  transform : rotate(-14deg);
  left: -23vw;
  width: 130vw;
  top: -32px;
}
#roadmap-yak{
  overflow: hidden;
  padding: unset;
  padding-top: 150px;
  padding-bottom: 150px;
}

.dimontalk .roadmap__way-item.roadmap__way-item--now{
  border: none;
}
.roadmap__way-image{
  width: 100px;
  height: 100px;
}

.dimontalk .roadmap__way-title.roadmap__way-title--now{
  color: black;
  padding: 20px;
  background: no-repeat;
  border: 1px solid #B3B4CA;
  border-radius: 11px;
  margin-top: 150px;
  font-size: 24px;
  line-height: 130%;
  width: 280px;
  min-height: 135px;

}
.dimontalk .roadmap__way-item.roadmap__way-item--now{
  height: unset;
  background: transparent;
}
.dimontalk .roadmap__way-image{
  top: 0px;
}
.dimontalk .roadmap__way-title.roadmap__way-title--now{
  position: unset;
  margin: 115px;
}


.dimontalk span{
  background: linear-gradient(98.59deg,#4e55ff 8.54%,#3a7cfd 34.57%,#24a1fb 62.94%,#00daf8 109.42%);
  background-clip: text;
  color: transparent;
}


.roadmap .subtitle{
  margin-bottom: 196px;
}

.roadmap::before{
  background-image: unset;
}

.wrap .roadmap__way-item{
  margin-left:95px;
  transform: rotate(15deg);
}
.wrap .roadmap__way-item:before{
  content: "";
  position: absolute;
  background-image: url('../../assets/topimg/arrow.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 25px;
  top: 14px;
  left: 95px;
  transform: rotate(-15deg);
}
.wrap .roadmap__way-item:after{
  content: "";
  position: absolute;
  background-image: url('../../assets/arrowsec.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 116%;
  height: 2.75px;
  top: 85px;
  right: 114px;
  z-index: -1;
  transform: rotate(-15deg);
}
.dimontalk > .nfts__slide{
  transform: rotate(13deg)!important;
  margin: 0px 275px;
}

.wrap {
  max-width: 100vw;
  margin: auto;
  padding: 20px;
  height: 850px;
  display: flex;
  margin-top: 140px;
  .subtitle {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}
.items-wrap {
  position: relative;
  display: flex;
  //overflow: hidden;
  user-select: none;
  gap: 20px;
  transform: rotate(-15deg);
  top: -110px;
}
.items-wrap:before,
.items-wrap:after {
  content: "";
  height: 100%;
  top: 0;
  width: 10%;
  position: absolute;
  z-index: 1;
  pointer-events: none;
}
.items-wrap:before {
  left: 0;

}
.items-wrap:after {
  right: 0;

}
.items {
  flex-shrink: 0;
  display: flex;
  //gap: 20px;
  counter-reset: item;
  justify-content: space-around;
  min-width: 79%;
}
.item {
  //background: #ccc;
  flex: 0 0 auto;
  width: 272px;
  height: 265px;
  counter-increment: item;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: end;
  font-size: 25px;
  font-weight: bold;
  color: #fff;
  margin: 10px 0;
  transition: all 0.1s ease-in-out;
}
.item:hover {
  //transform: scale(1.05);
  //box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.item:before {
  content: counter(item);
}

.marquee {
  animation: scroll 60s linear infinite;
}
.reverce {
  animation-direction: reverse;
}
.items-wrap:hover .marquee {
  //animation-play-state: paused;
}

.perfscan {
  margin: 20px 0;
  text-align: center;
  bottom: 0;
  background: #fff;
  padding: 5px;
}
.perfscan hr {
  border: solid #999;
  border-width: 1px 0 0 0;
  max-width: 50%;
  margin: 0 auto 20px;
}
.perfscan a {
  color: #000;
  font-weight: bold;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - 20px));
  }
}



@media (max-width: 768px) {
  .roadmap__way-image {
    width: 50px;
    height: 50px;
  }
  .dimontalk .roadmap__way-title.roadmap__way-title--now {
    margin: 65px;
    font-size: 16px;
    padding: 10px;
    width: 190px;
    min-height: 84px;
  }
  .item {
    width: 165px;
    height: 180px;
  }
  .wrap {
    height: 395px;
    overflow: hidden;
    margin-top: 65px;
  }
  .items-wrap {
    top: -231px;
  }
  .wrap .roadmap__way-item:before {
    width: 122%;
    top: 4px;
    left: 26px;
  }
  .wrap .roadmap__way-item:after {
    top: 41px;
    right: 47px;
  }
  .blast .card__img {
    width: 350px;
  }
  .features {
    overflow-x: hidden;
  }

}