.modal {
	--transition-time: 0.3s;
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 1000;
	background: rgba(43, 45, 101, 0.6);
  backdrop-filter: blur(30px);
	overflow-y: auto;
	overflow-x: hidden;
	text-align: center;
	opacity: 0;
	visibility: hidden;
	transition: opacity var(--transition-time), visibility var(--transition-time);
}

.modal::before {
	content: '';
	display: inline-block;
	vertical-align: middle;
	height: 100%;
}

.modal__container {
	position: fixed;
    left: 50%;
    top: 50%;
    z-index: 1000;
    transform: translate(-50%, -50%);
	width: auto;
	margin: 50px auto;
	display: none;
	vertical-align: middle;
	background-color: #fff;
	border-radius: 20px;
	cursor: default;
  text-align: center;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  border-radius: 12px;
}

.modal.is-open {
	opacity: 1;
	visibility: visible;
	transition: opacity var(--transition-time), visibility var(--transition-time);
}

.modal__container.modal-open {
	display: inline-block;
}

.fade {
	opacity: 0;
	transition: opacity var(--transition-time);
}

.fade.animate-open {
	opacity: 1;
	transition: opacity var(--transition-time);
}

.fadeInUp {
	opacity: 0;
	transform: translateY(-100px);
	transition: opacity var(--transition-time), transform var(--transition-time);
}

.fadeInUp.animate-open {
	opacity: 1;
	transform: translateY(0);
	transition: opacity var(--transition-time), transform var(--transition-time);
}

.disable-scroll {
	position: relative;
	overflow: hidden;
	height: 100vh;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
}

.modal__close {
  width: 24px;
  height: 24px;
  color: #B3B4CA;
  cursor: pointer;
}

.modal__wallet {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  min-height: 430px;
}

.modal__head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
}

.modal__title {
  font-family: "Rubik";
  font-size: 22px;
  line-height: 28px;
  font-weight: 700;
}

.modal__inner {
  border-top: 1px solid #E5E8F3;
  border-bottom: 1px solid #E5E8F3;
  padding: 24px;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 20px;
  justify-content: center;
  min-width: 300px;
}

.modal__metamask {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 115px;
  height: 75px;
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
}

.modal__text {
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
}

.modal__bottom {
  padding: 32px;

  .wallet {
    margin-top: 16px;
    width: 100%;
  }
}

.modal__metamask-name {
  font-size: 14px;
  line-height: 24px;
}

.modal__farm {
  display: flex;
  justify-content: space-between;
  padding: 61px;
  position: relative;

  .modal__close {
    position: absolute;
    right: 32px;
    top: 32px;
  }
}

.modal__farm-info {
  margin-left: 65px;
  max-width: 385px;
  padding-top: 40px;
  text-align: left;
}

.modal__mint {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  min-height: 420px;
}

.modal__mint.load-modal {
  min-height: auto;
  height: auto;
  padding: 48px 24px;
  .modal-close {
    position: absolute;
    right: 24px;
    top: 24px;
  }
}

.modal__loader {
    width: 62px;
    height: 62px;
    margin: 0 auto;
    margin-bottom: 22px;
    &.load {
      animation: rotateCircle 3s linear infinite;

    }
}
@keyframes rotateCircle {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.modal__button {
  font-family: Rubik;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.96px;
  text-align: center;
  color: #FFFFFF;
  width: 276px;
  padding: 12px 17px 12px 17px;
  border-radius: 8px;
  margin: 0 auto;

  background: linear-gradient(92.05deg, #FFAB5D 7.98%, #FEE97B 131.44%);
}
.modal__load-title {
  font-family: "Rubik";
  font-size: 22px;
  font-weight: 500;
  line-height: 28.6px;
  text-align: center;

}

.modal__load-text {
  font-family: "NotoSans";
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  text-align: center;
  margin-bottom: 22px;

}

.modal__inner-mint {
  border-top: 1px solid #E5E8F3;
  padding: 18px 24px;
  text-align-last: left;
  font-size: 14px;
  line-height: 24px;
  color: #B3B4CA;

  .modal__text {
    margin-bottom: 8px;
    font-weight: 400;

    span {
      margin-left: 5px;
      font-weight: 700;
      color: #2B2D65;
    }
  }
}

.modal__mint-total {
  margin: 26px 0;
  width: 250px;
  min-height: 142px;
  padding: 18px;
  background: #F8F9FD;
  border: 1px solid #E5E8F3;
  border-radius: 12px;

  li + li {
    margin-top: 20px;
  }
}

.modal__mint-line {
  display: flex;
  justify-content: space-between;
  height: 45px;
}

.modal__total-checkout {
  min-width: 75px;
  display: flex;
  flex-direction: column;
  align-items: end;

  .card__price {
    font-size: 14px;
    line-height: 24px;
    font-weight: 700;
    color: #2B2D65;
  }
}

.modal__total-price {
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
}

.modal__content-card {
  max-width: 413px;
  width: 100%;
  min-height: 546px;
  background: #FFFFFF;
  border: 1px solid #E5E8F3;
  border-radius: 18px;
  padding: 75px 65px;

  &--over {
    padding: 75px 36px;
  }
}

.modal__content-less {
  position: relative;
  width: 280px;
  height: 393px;
  box-shadow: 0px 0px 18px rgba(217, 221, 240, 0.61);
  border-radius: 12px;

  .content__card {
    max-width: 280px;
    min-width: auto;
    min-height: 365px;
    padding: 10px;
    box-shadow: none;

    .card__image {
      width: 260px;
      height: 260px;
    }

    .card__img {
      width: 260px;
      height: 260px;
    }

    .card__info {
      padding-top: 12px;
    }

    .card__series {
      font-size: 12px;
      line-height: 15px;
    }

    .card__title {
      font-size: 18px;
      line-height: 24px;
    }

    .card__divider {
      margin-top: 12px;
    }

    .card__footer {
      margin-top: 21px;
    }
  }

  .content__arrows,
  .swiper-pagination-fraction {
    bottom: -45px;
  }
}

.modal__content-over {
  position: relative;
}

.modal__over-slider {
  height: 478px;
  width: 340px;
}


.content__over-slide {
  .content__card {
    padding: 5px;
    max-width: 160px;
    box-sizing: border-box;
    box-shadow: none;
    border: 1px solid rgba(217, 221, 240, 0.61);
    min-width: auto;
    min-height: auto;
  }

  .card__image {
    max-width: 150px;
    max-height: 150px;
  }

  .card__info {
    padding-top: 7px;
  }

  .card__series {
    font-size: 7px;
    line-height: 9px;
  }

  .card__title {
    font-size: 10px;
    line-height: 14px;
  }

  .card__divider {
    margin-top: 7px;
  }

  .card__footer {
    margin-top: 7px;
  }

  .card__status {
    font-size: 7px;
    line-height: 7px;
  }
}

.content__over-slide.swiper-slide {
  height: calc((100% - 20px) / 2);
  overflow: hidden;
}


.modal__bids {
  display: flex;
  flex-direction: column;
  min-height: 680px;
  max-width: 635px;
  width: 100%;
}

.modal__inner-bids {
  border-top: 1px solid #E5E8F3;
  max-width: 635px;
  width: 100%;
}

.modal__bids-list {
  max-height: 590px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #4E55FF;
    border-radius: 100px;
  }
}

.modal__bids-item {
  height: 100px;
  padding-left: 42px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  line-height: 23px;
}

.modal__bids-info {
  display: flex;
  align-items: center;
  text-align: left;
}

.modal__bids-avatar {
  height: 64px;
  width: 64px;
  border-radius: 12px;
  margin-right: 18px;

  img {
    border-radius: 12px;
  }
}

.modal__bids-id {
  font-size: 12px;
  line-height: 20px;
  color: #B3B4CA;

  span {
    color: #2B2D65;
    font-weight: 700;
  }
}

.modal__bids-amount {
  margin-left: 60px;
  display: flex;

  span {
    margin-right: 5px;
  }
}

.modal__bids-cancel {
  margin-left: 85px;
  margin-right: 42px;
  width: 156px;
  height: 42px;
  padding: 12px 35px;
  border: 1px solid #B3B4CA;
  border-radius: 8px;
  font-family: "Rubik";
  font-size: 14px;
  line-height: 17px;
  color: #B3B4CA;
  cursor: pointer;
  transition: 0.4s;

  &:hover {
    border: 1px solid #2B2D65;
    color: #2B2D65;
  }
}

.modal__transfer {
  width: 298px;
  min-height: 325px;
}

.modal__inner-transfer {
  border-top: 1px solid #E5E8F3;
  padding: 14px 24px 24px;
}

.modal__sell-block {
  margin-top: 18px;
  border: 1px solid #E5E8F3;
  border-radius: 12px;
  padding: 18px;

  .modal__sell-line + .modal__sell-line {
    margin-top: 18px;
  }
}

.modal__sell-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 24px;

  span {
    font-weight: 500;
  }

  input {
    width: 57px;
    background: #F8F9FD;
    border-radius: 4px;
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
    color: #2B2D65;
    text-align: center;

    &::placeholder {
      color: #E5E8F3;
    }
  }
}

.modal__sell-text {
  font-size: 12px;
  line-height: 20px;
  color: #B3B4CA;
}

.modal__transfer-text {
  margin-top: 18px;
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  text-align: left;
}

.modal__transfer-input {
  margin-top: 6px;
  height: 44px;
  width: 100%;
  background-color: #F8F9FD;
  border: 1px solid #E5E8F3;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 12px 18px;
  font-size: 12px;
  line-height: 20px;
  color: #2B2D65;
  font-weight: 400;

  &::placeholder {
    color: #B3B4CA;
  }

  &:focus {
    border: 1px solid #2B2D65;
  }
}

.modal__transfer-btn {
  margin-top: 22px;
  width: 100%;
  height: 42px;
  background: #E5E8F3;
  border-radius: 8px;
  font-family: "Rubik";
  font-size: 16px;
  line-height: 19px;
  font-weight: 700;
  color: #fff;

  &--active {
    background: linear-gradient(98.59deg, #4E55FF 8.54%, #3A7CFD 34.57%, #24A1FB 62.94%, #00DAF8 109.42%);
  }
}

.auc__info .text {
  max-height: 275px;
  overflow: auto;
}

.modal__mint {
  min-height: 215px;
  max-width: 380px;
  height: 530px;
}

div[data-target="mint"] .modal__text span {
  font-weight: 500;
}

.modal__mint-total {
  /* display: none; */
  width: unset;
  min-height: 80px;
  border-color: #49CF6F;
}

div[data-target="mint"] .modal__total-checkout {
  align-items: unset;
  /* font-size: 13px; */
}

.blast-con {
  background: #49CF6F;
  border-radius: 20px;
  display: flex;
  height: 25px;
  color: white;
  width: 90px;
  padding: 5px;
  place-content: center;
  align-items: center;
}

.class-ir {
  color: #2b2d65;
  font-size: 14px;
  font-weight: 700;
}

.ir-cont {
  display: flex;
  background: #f8f9fd;
  border-radius: 8px;
  justify-content: end;
}

.ir-last {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  padding-top: 7px;
  padding-bottom: 15px;
}

.ir-cont img {
  height: 16px;
  width: 16px;
  align-self: center;
}

.blast-link {
  height: 42px;
  width: 90%;
  padding-left: 5px;
  padding: 10px 16px;
  font-size: 16px;
}