.features {
  padding: 155px 0;

  &__inner {
    display: flex;
    justify-content: space-between;
  }

  &__info {
    max-width: 580px;
  }

  &__details {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 90px;
    max-width: 445px;
    .text {
      font-size: inherit;
      color: inherit;
      line-height: inherit;
    }
  }

  &__down {
    margin-top: 53px;
    width: 50px;
    height: 50px;
  }

  &__nft-status {
    position: relative;
    margin-top: 45px;
    height: 97px;
    width: 235px;
    animation-duration: 1.4s;
    animation-name: bounceInDown;


    .card__status {
      padding: 4px 13px 7px;
    }

    svg {
      margin-top: 7px;
    }

    span {
      font-size: 17px;
      line-height: 17px;
      font-weight: 600;
    }

    .legendary {
      top: 17px;
      left: 50%;
      // width: 136px;
      position: absolute;
      transform: translateX(-50%) rotate(-2.71deg);
      z-index: 1;
      // animation-duration: 1.3s;
      // animation-name: bounceInDown;
      // animation-delay: 0.1s;
    }

    .epic {
      position: absolute;
      width: 84px;
      right: 0;
      top: 40px;
      z-index: 4;
      // animation-duration: 1.33s;
      // animation-delay: 0.2s;
    }

    .rare {
      position: absolute;
      z-index: 3;
      left: 20px;
      top: 50%;
      font-size: 17px;
      line-height: 17px;
      transform: rotate(-8.89deg);
      z-index: 2;
      // animation-duration: 1.36s;
      // animation-delay: 0.3s;
    }

    .uncommon {
      position: absolute;
      font-size: 17px;
      line-height: 17px;
      bottom: -7px;
      right: 0;
      transform: rotate(26.89deg);
      z-index: 3;
      // animation-duration: 1.39s;
      // animation-delay: 0.4s;
    }

    .common {
      position: absolute;
      font-size: 17px;
      line-height: 17px;
      left: 50%;
      bottom: -20px;
      transform: translateX(-50%) rotate(9.01deg);
      z-index: 5;
      // animation-duration: 1.42s;
      // animation-delay: 0.5s;
    }
  }

  &__image {
    .card {
      min-width: 375px;
      min-height: 535px;
      border-radius: 32px;
      padding: 14px;
      transform: matrix(1, 0.23, 0, 0.98, 0, 0);
      filter: drop-shadow(-7px 4px 15px rgba(172,181,215,.55));
      box-shadow: 52px -35px 10px 10px rgba(255, 255, 255, 0.87);
    }

    .card__image {
      min-width: 100%;
      min-height: 350px;
    }

    .card__info {
      padding-top: 15px;
    }

    .card__series {
      font-size: 16px;
      line-height: 22px;
    }

    .card__title {
      font-size: 24px;
      line-height: 38px;
    }

    .card__divider {
      margin-top: 15px;
    }

    .card__footer {
      margin-top: 20px;
    }

    .card__status  {
      font-size: 16px;
      line-height: 16px;
    }

    .card__price {
      font-size: 24px;
      line-height: 32px;
      font-weight: 700;

      &::before {
        width: 21px;
        height: 21px;
        left: -25px;
      }
    }
  }
}

.blast .descr {
  margin-top: 90px;
}
.blast .features__details {
  margin-top: unset;
}
.blast .card__img {
  bottom: 90px;
  position: relative;
  width: 445px;
  height: 525px;
  object-fit: cover;
  right: 5px;
}

.features__person{
  max-width: 555px;
  z-index: 3;
}

.features__wrap{
  flex-wrap: wrap;
  position: relative;
}
.features__wrap .features__details{
  max-width: 520px;
}

.features__wrap::before{
  content: "";
  position: absolute;
  background-image: url('../../assets/details-img1.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 373px;
  height: 333px;
  top: 370px;
  /*right: -34%;*/
  right: 0%;
  /*-webkit-transform: translateX(-45%);*/
  /*transform: translateX(-45%);*/
  -webkit-filter: blur(26px);
  filter: blur(26px);
  -webkit-transform: rotate(15deg);
  transform: rotate(15deg);
}

.features__wrap::after{
  content: "";
  position: absolute;
  background-image: url('../../assets/details-img2.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 373px;
  height: 333px;
  bottom: 155px;
  left: -25%;
  /*-webkit-transform: translateX(-45%);*/
  /*transform: translateX(-45%);*/
  z-index: 1;
  -webkit-filter: blur(26px);
  filter: blur(26px);
  -webkit-transform: rotate(-15deg);
  transform: rotate(-15deg);
}

.white-back{
  background: white;
  border-radius: 18px;
  height: 331px;
  position: relative;
  margin-top: 100px;
  top: 60px;
}

.features-profile__avatar{
  position: absolute;
  top: -85px;
  left: 35%;
  height: 160px;
  width: 160px;
}
.person-title{
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 10px;
  padding-top: 100px;
  text-align: center;
}
.person-desc{
  text-align: center;
  color: #B3B4CA;
  font-size: 16px;
  line-height: 27px;
  margin-bottom: 10px;
}
.person-blue-desc{
  font-size: 16px;
  color: #3A7CFD;
  text-align: center;
}

.extrabid {
  padding-top: unset;
  padding-bottom: 150px!important;
}

.extrabid .container__small {
  max-width: 1125px;
}

.extrabid .features__details {
  max-width: 585px;
}

.extrabid .features__image {
  width: 275px;
}

.extrabid .features__image .card__calculator-content {
  justify-content: center;
  flex-direction: column;
  margin-top: 35px;
}

.extrabid .features__image .card__calculator-content .text {
  margin-top: 10px;
  font-weight: 400;
  color: #2B2D65;
}

.extrabid .card__calculator-btn.card__btn {
  background: linear-gradient(92.05deg, #ffab5d 7.98%, #fee97b 131.44%);
}